import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {ApiJournalProps, ApiJournalSimpleProps} from "~/projectCommon/frontend/model/ApiJournalProps";
import type {FrontendClientOptions} from "~/composables/api/frontend/FrontendClientOptions";

interface GetListParams {
  page: number
  pageSize: number
  lang?: string
}

interface GetListResponse {
  journals: ApiJournalSimpleProps[]
  page: number
  totalCount: number
}

interface GetByIdParams {
  lang?: string
}

interface GetByIdResponse {
  journal: ApiJournalProps
}

export function createJournalApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/journals"
  const lang = options.lang
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getList: async (params: GetListParams | null = null): Promise<GetListResponse> => {
      params ||= {
        page: 1,
        pageSize: 1000,
      }
      params.lang ||= lang
      const { data } = await client.get(`${rootDir}/`, params, fetchOptions2)
      return {
        journals: data.journals as ApiJournalSimpleProps[],
        page: data.page as number,
        totalCount: data.totalCount as number,
      }
    },
    getById: async (id: string, params: GetByIdParams | null = null): Promise<GetByIdResponse> => {
      params ||= {}
      params.lang ||= lang
      const { data } = await client.get(`${rootDir}/${id}`, params, fetchOptions2)
      return {
        journal: data.journal as ApiJournalProps,
      }
    }
  }
}
