import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {FrontendClientOptions} from "~/composables/api/frontend/FrontendClientOptions";
import type {ApiAreaGuideProps} from "~/projectCommon/frontend/model/ApiAreaGuideProps";

export function createAreaGuideApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const rootDir = "/areaGuides"
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    getById: async (id: string) => {
      const { data } = await client.get(`${rootDir}/${id}`, null, fetchOptions2)
      return {
        areaGuide: data.areaGuide as ApiAreaGuideProps,
      }
    },
  }
}