import urlJoin from "url-join";
import {type ApiFetchOptions, useApiClientBase} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {FrontendClientOptions} from "~/composables/api/frontend/FrontendClientOptions";
import {createTopApiClient} from "~/composables/api/frontend/TopApiClient";
import {createAreaMapApiClient} from "~/composables/api/frontend/AreaMapApiClient";
import {createPhotoApiClient} from "~/composables/api/frontend/PhotoApiClient";
import {createPostApiClient} from "~/composables/api/frontend/PostApiClient";
import {createPostSeriesApiClient} from "~/composables/api/frontend/PostSeriesApiClient";
import {createPlaceApiClient} from "~/composables/api/frontend/PlaceApiClient";
import {createJournalApiClient} from "~/composables/api/frontend/JournalApiClient";
import {createAuthApiClient} from "~/composables/api/frontend/AuthApiClient";
import {createAreaGuideApiClient} from "~/composables/api/frontend/AreaGuideApiClient";

/**
 * 呼び出しはscript setupの最初の方で行うこと。
 * クライアントでのみ呼び出すとエラーになるので注意。
 */
export const useFrontendApiClient = () => {
  // nuxtのcomposableの呼び出しはここで行うこと。クライアントのみで呼び出しさせる場所で行わないこと。
  const { locale } = useI18n()
  const lang = locale.value
  const baseUrl = urlJoin(useRuntimeConfig().public.apiBaseUrl, "/v3.0")
  const client = useApiClientBase(baseUrl)
  const options: FrontendClientOptions = {
    lang: lang,
  }
  return {
    auth: (fetchOptions?: ApiFetchOptions) => createAuthApiClient(client, options, fetchOptions),
    top: (fetchOptions?: ApiFetchOptions) => createTopApiClient(client, options, fetchOptions),
    post: (fetchOptions?: ApiFetchOptions) => createPostApiClient(client, options, fetchOptions),
    postSeries: (fetchOptions?: ApiFetchOptions) => createPostSeriesApiClient(client, options, fetchOptions),
    journal: (fetchOptions?: ApiFetchOptions) => createJournalApiClient(client, options, fetchOptions),
    areaMap: (fetchOptions?: ApiFetchOptions) => createAreaMapApiClient(client, options, fetchOptions),
    areaGuide: (fetchOptions?: ApiFetchOptions) => createAreaGuideApiClient(client, options, fetchOptions),
    place: (fetchOptions?: ApiFetchOptions) => createPlaceApiClient(client, options, fetchOptions),
    photo: (fetchOptions?: ApiFetchOptions) => createPhotoApiClient(client, options, fetchOptions),
  }
}
