import {
  type ApiClientBase,
  type ApiFetchOptions,
  DefaultApiFetchOptions
} from "~/stNuxtCommon/composables/api/useApiClientBase";
import type {FrontendClientOptions} from "~/composables/api/frontend/FrontendClientOptions";

interface LoginParams {
  userId: string
  password: string
  pinCode: string
}

interface LoginResponse {
  email: string
  apiAccessToken: string
}

export function createAuthApiClient(client: ApiClientBase, options: FrontendClientOptions, fetchOptions?: ApiFetchOptions) {
  const fetchOptions2 = fetchOptions || DefaultApiFetchOptions
  return {
    login: async (params: LoginParams): Promise<LoginResponse> => {
      const {data} = await client.post(`/auth/login`, {
        userId: params.userId,
        password: params.password,
        pinCode: params.pinCode,
      }, fetchOptions2)
      return {
        email: data.email,
        apiAccessToken: data.apiAccessToken,
      }
    },
  }
}
